import { OpenRequestTypes } from '../models/open-requests/open-request-types.model';
import { OrganizationRequest } from '../../../models/organization/organization-request.interface';
import { OpenRequestStatus } from '../models/open-requests/open-requests-status.model';
import { ErrorMessage } from '../../../models/error-message.interface';

export const filterChangeBusinessInformation = (
  changeRequest: Partial<OrganizationRequest>
): boolean =>
  changeRequest?.req_type !== OpenRequestTypes.CHANGE_BUSINESS_INFORMATION;

export const filterChangeRequestByStatusNew = (
  changeRequest: Partial<OrganizationRequest>
): boolean =>
  changeRequest?.status === OpenRequestStatus.NEW &&
  changeRequest.req_type !== OpenRequestTypes.NEW_SERVICE &&
  changeRequest.req_type !== OpenRequestTypes.CHANGE_BUSINESS_INFORMATION;

export const filterByTypeNewOrg = (
  changeRequest: Partial<OrganizationRequest>
): boolean => changeRequest?.req_type === OpenRequestTypes.NEW_ORGANIZATION;

export const filterChangeRequestNewOrganization = (
  changeRequest: Partial<OrganizationRequest>
): boolean =>
  filterChangeRequestByStatusNew(changeRequest) &&
  filterByTypeNewOrg(changeRequest);

export const filterChangeRequestByStatusOpenRequest = (
  changeRequest: Partial<OrganizationRequest>
): boolean =>
  changeRequest?.status === OpenRequestStatus.UPDATED ||
  changeRequest?.status === OpenRequestStatus.NEW ||
  changeRequest?.status === OpenRequestStatus.ID_APPROVED ||
  changeRequest?.status === OpenRequestStatus.ID_REJECTED ||
  changeRequest?.status === OpenRequestStatus.ACCEPTED;

export const filterByValidationFailedError = ({
  error,
}: {
  error: ErrorMessage;
}): boolean => error.Error === 'errorTexts.general.validationFailed';
