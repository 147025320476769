export const environment = {
  production: true,
  environmentName: 'test',
  runReduxDevTools: true,
  portbaseSupport: 'https://support.portbase.com/',
  turnstilSiteKey: '1x00000000000000000000AA',
  portalUrl: 'https://www.test-iamconnected.eu/',
  pcsUrl: 'https://www.accept.portbase.com',
  portbaseServiceUrl: 'https://www.portbase.com/services/',
  connectorUrl: 'https://iamc-connect.accept.portbase.com/',
  pcsLoginUrl:
    'https://sso.accept.portbase.com/sso/UI/Login?realm=portbase&goto=https://www.accept.portbase.com/portal/start.do&locale=en',
  apiEndpoints: {
    tokenName: 'SsoToken',
    externalLoginPage: 'https://login.test-iamconnected.eu',
    externalLoginPageWithClientId:
      'https://login.test-iamconnected.eu?client_id=0oap97pwtlfTG2j1H0h7&response_type=code',
    externalLoginPageReturnUrl:
      '&redirect_uri=https://www.test-iamconnected.eu/auth/callback',
    authenticationApiUrl: 'https://auth.test-iamconnected.eu/authn',
    authenticationLogoutEndpoint:
      'https://autn.test-iamconnected.eu/oauth2/ausloo3mdbfI5t1BM0h7/v1/logout',
    authenticationLogoutReturnUrl:
      '?post_logout_redirect_uri=https://www.test-iamconnected.eu',
    activationApiUrl: 'https://auth.test-iamconnected.eu/authn/activate',
    resourcesApiUrl: 'https://api.test-iamconnected.eu',
    verifyTokenApiUrl: 'https://autn.test-iamconnected.eu/api/v1/sessions/me',
    fileserverApiUrl: 'https://files.test-iamconnected.eu'
  },
  rum: {
    id: '66164a94-a511-4182-99ce-5e811ca97a7d',
    version:'1.0.0',
    region:'eu-west-1',
    guestRoleArn: 'arn:aws:iam::571844716410:role/test-cognito_rum_unauthenticated',
    identityPoolId: 'eu-west-1:25729b50-7c7f-4a9a-8e88-ef757e4e553b',
    endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com'
  }
};
