export enum OpenRequestTypes {
  ACTIVATE = 'activate',
  NEW_ORGANIZATION = 'new_organization',
  NEW_SERVICE = 'new_service',
  CHANGE_SERVICE = 'change_service',
  CHANGE_BUSINESS_INFORMATION = 'change_business_information',
  VERIFY_EMAIL = 'verify_email',
  CHANGE_USER_PROFILE = 'change_user_profile',
  RESEND_EMAIL_VERIFICATION = 'resend_email_verification',
  UPDATE_ORGANIZATION_DETAILS = 'update_organization_details',
}
