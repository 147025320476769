import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IamRestService } from './iam-rest.service';

// RXJS
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { stripPortalUri } from '../utils/url.utils';
import { environment } from 'projects/iam/src/environments/environment';
import { LANGUAGES } from '../../constants/languages';
import { ErrorMessage } from '../../models/error-message.interface';
import { PkceService } from './pkce.service';

interface StateToken {
  state_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends IamRestService {
  private verifyToken$: Observable<unknown | ErrorMessage>;

  constructor(private _http: HttpClient, private _store: Store, private pkceService: PkceService) {
    super(_http, _store);
    this.verifyToken$ = this.get(`${environment.apiEndpoints.verifyTokenApiUrl}`).pipe(share());
  }

  verifyToken(): Observable<unknown | ErrorMessage> {
    return this.verifyToken$;
  }

  getIdTokenFromApi(code: string): Observable<{ id_token: string }> {
    const code_verifier = localStorage.getItem('code_verifier');
    const url = `${environment.apiEndpoints.authenticationApiUrl}/v2/token?code=${code}${environment.apiEndpoints.externalLoginPageReturnUrl}&code_verifier=${code_verifier}`;
    return this.get(url);
  }

  logOut(): Observable<unknown> {
    const url = `${environment.apiEndpoints.authenticationApiUrl}/logout`;
    localStorage.removeItem('code_verifier');
    return this.get<unknown>(url);
  }

  logIn(language: string): void {
    const uri = stripPortalUri(window.location.href.split('?')[0]);

    if (uri.length > 0) {
      localStorage.setItem('redirect_uri', uri);
    }

    this.pkceService
      .pkceChallenge()
      .subscribe(pkceCodes => {
        localStorage.setItem('code_verifier', pkceCodes.code_verifier);
        // redirect to external login page
        window.location.assign(
          environment.apiEndpoints.externalLoginPageWithClientId +
            environment.apiEndpoints.externalLoginPageReturnUrl +
            '&lang=' +
            this.getLanguageParams(language) +
            '&code_challenge=' +
            pkceCodes.code_challenge
        );
      });
  }

  postActivation(token: string): Observable<StateToken> {
    const url = environment.apiEndpoints.activationApiUrl;
    return this.post<StateToken>(url, { token });
  }

  getLanguageParams(language: string, key = 'id'): string {
    return language !== 'nl' && language !== 'nl_NL' ? language : LANGUAGES?.find(lang => lang[key] === language).okta;
  }

  redirectLogin(stateToken: string, language: string): void {
    const currentPos = window.location.href.indexOf('redirect_uri=');
    if (currentPos > -1) {
      const redirectUrl = window.location.href.substring(currentPos + 13);
      localStorage.setItem('redirect_uri', redirectUrl);
    }
    window.location.assign(
      environment.apiEndpoints.externalLoginPage +
        '/?state_token=' +
        stateToken +
        '&lang=' +
        this.getLanguageParams(language, 'locale')
    );
  }
}
