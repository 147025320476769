import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// RXJS
import { combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

// Services
import { OrganizationsService as OrganizationService } from '../../features/organization/services/organizations.service';
import { PoliciesService } from '../../features/organization/services/policies.service';

// Models
import { OrganizationsService } from '../../core/services/organizations.service';
import { environment } from 'projects/iam/src/environments/environment';
import { AuthenticationService } from '../../core/services/authentication.service';
import { convertRouteToPortalPage } from '../../core/utils/route-to-portal-page.utils';
import { portalQuery } from '../selectors/portal.selectors';
import { PortalInfoLoad } from '../actions/portal.actions';
import { getRouterUrl } from '../selectors/router.selectors';
import { AppState } from '../reducers/app.reducer';
import {
  DecoupleSuccessAndLogout,
  DecoupleSuccessAndReload,
  DecoupleUserFromOrganization,
  LoadCurrentOrganization,
  LoadOrganizations,
  LoadSelectedOrganization,
  OrganizationDeleted,
  OrganizationsLoaded,
  OrganizationsLoadError,
  OrganizationUpdated,
  SecurityOrganizationUpdated,
  SelectedOrganizationLoaded,
  UpdateOrganization,
  UpdateSecurityOrganization,
} from '../actions/organizations.actions';
import { Organization } from '../../models/organization/organization.interface';
import { ShowSnackbar } from '../../libs/portbase-snackbar-components/src/lib/store/snackbar.actions';
import { CloseDialog } from '../../libs/portbase-dialog-component/lib/store/actions/dialog.action';
import { ErrorMessage } from '../../models/error-message.interface';

@Injectable()
export class OrganizationsEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private policiesService: PoliciesService,
    private organizationService: OrganizationsService,
    private organizationsService: OrganizationService,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private router: Router
  ) {}

  userId$: Observable<string | undefined> = this.store.select(
    portalQuery.getUserId
  );
  portalPage$ = this.store.pipe(
    // @ts-ignore
    select(getRouterUrl),
    map(convertRouteToPortalPage)
  );

  loadOrganizations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadOrganizations),
        switchMap(action =>
          this.organizationService.getOrganizations().pipe(
            map((result: Organization[]) =>
              OrganizationsLoaded({
                payload: result,
              })
            ),
            catchError(error =>
              of(
                OrganizationsLoadError({
                  error,
                  actionSourceType: action.type,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  loadCurrentOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadCurrentOrganization),
        withLatestFrom(this.store.select(portalQuery.getCurrentOrganizationId)),
        switchMap(([, selectedOrganizationId]) =>
          of(
            LoadSelectedOrganization({
              id: selectedOrganizationId ?? '',
            })
          )
        )
      ),
    { dispatch: true }
  );

  loadSelectedOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadSelectedOrganization),
        switchMap(action =>
          this.organizationsService.getOrganization(action.id).pipe(
            map((organization: Organization) =>
              SelectedOrganizationLoaded({
                organization,
              })
            ),
            catchError(error =>
              of(
                OrganizationsLoadError({
                  error,
                  actionSourceType: action.type,
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  loadSelectedOrganizationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationsLoadError),
        tap(() => this.router.navigate(['/'])),
        map(({ error }) =>
          ShowSnackbar({
            message: this.translateService.instant(
              error.Error || 'errorTexts.general.badRequest'
            ),
            level: 'danger',
          })
        )
      ),
    { dispatch: true }
  );

  updateOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateOrganization),
        switchMap(action =>
          this.organizationsService
            .updateOrganization(action.organization)
            .pipe(
              switchMap(organization => [
                OrganizationUpdated({
                  organization,
                }),
                ShowSnackbar({
                  message: this.translateService.instant(
                    'successTexts.updateOrganization'
                  ),
                  level: 'success',
                }),
                CloseDialog(),
              ]),
              catchError((err: ErrorMessage) =>
                of(
                  ShowSnackbar({
                    message: this.translateService.instant(
                      err.Error || 'errorTexts.general.badRequest'
                    ),
                    level: 'danger',
                  })
                )
              )
            )
        )
      ),
    { dispatch: true }
  );

  updateSecurityOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateSecurityOrganization),
        switchMap(action =>
          combineLatest([
            this.organizationsService.updateOrganization(action.organization),
            this.userId$,
            this.portalPage$,
          ]).pipe(
            switchMap(([organization, userId, portalPage]) => [
              SecurityOrganizationUpdated({
                organization,
              }),
              ShowSnackbar({
                message: this.translateService.instant(
                  'successTexts.updateOrganization'
                ),
                level: 'success',
              }),
              LoadSelectedOrganization({
                id: organization.id,
              }),
              CloseDialog(),
              // @ts-ignore
              PortalInfoLoad({ userId, portalPage }),
            ]),
            catchError((err: ErrorMessage) =>
              of(
                ShowSnackbar({
                  message: this.translateService.instant(
                    err.Error || 'errorTexts.general.badRequest'
                  ),
                  level: 'danger',
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  decoupleFromOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DecoupleUserFromOrganization),
        switchMap(({ organizationId, mainOrganization }) =>
          combineLatest([
            this.policiesService.decoupleFromOrganization(organizationId),
            this.userId$,
            this.portalPage$,
          ]).pipe(
            take(1),
            switchMap(([, userId, portalPage]) =>
              mainOrganization
                ? [DecoupleSuccessAndLogout()]
                : [
                    DecoupleSuccessAndReload({
                      organizationId,
                      userId,
                      portalPage,
                    }),
                  ]
            ),
            catchError((err: ErrorMessage) =>
              of(
                ShowSnackbar({
                  message: this.translateService.instant(
                    err.Error || 'errorTexts.general.badRequest'
                  ),
                  level: 'danger',
                })
              )
            )
          )
        )
      ),
    { dispatch: true }
  );

  decoupleSuccessAndLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DecoupleSuccessAndLogout),
        switchMap(() =>
          this.authService.logOut().pipe(
            tap(() => {
              window.location.assign(
                `${environment.apiEndpoints.authenticationLogoutEndpoint}${
                  environment.apiEndpoints.authenticationLogoutReturnUrl
                }&id_token_hint=${localStorage.getItem('id_token')}`
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  decoupleSuccessAndReload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DecoupleSuccessAndReload),
        switchMap(({ organizationId, userId, portalPage }) => [
          OrganizationDeleted({
            id: organizationId,
          }),
          ShowSnackbar({
            message: this.translateService.instant('snackbar.form.success'),
            level: 'success',
          }),
          PortalInfoLoad({ userId, portalPage }),
        ])
      ),
    { dispatch: true }
  );
}
