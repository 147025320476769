import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

// RXJS
import {of} from 'rxjs';
import {catchError, filter, map, switchMap, tap, withLatestFrom,} from 'rxjs/operators';

// NGRX
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CheckLogin,
  Login,
  LoginComplete,
  LoginFailure,
  LoginSuccess,
  Logout,
  LogoutComplete,
} from '../../store/actions/auth.actions';
import {PortalInfoLoad} from '../../../store/actions/portal.actions';
import {selectQueryParameters} from '../../../store/selectors/router.selectors';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {parseJwt} from '../../../core/utils/jwt.utils';
import {convertRouteToPortalPage} from '../../../core/utils/route-to-portal-page.utils';
import {PortalPages} from '../../../models/portal/portal-pages';
import {appQuery} from '../../../store/selectors/app.selectors';
import {State} from '../../../store/reducers/state';
import { RumService } from '../../../features/organization/services/rum.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private rumService: RumService,
    private router: Router,
    private store: Store<State>
  ) {}

  portalPage$ = this.router.events.pipe(
    filter(event => event instanceof NavigationStart),
    map(event => event['url']),
    map(convertRouteToPortalPage)
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Login),
        withLatestFrom(this.store.pipe(select(appQuery.getSelectedLanguage))),
        tap(([, selectedLanguage]) => this.authService.logIn(selectedLanguage))
      ),
    { dispatch: false }
  );

  loginComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginComplete),
        withLatestFrom(this.store.pipe(select(selectQueryParameters))),
        switchMap(([, queryParams]) =>
          // @ts-ignore
          this.authService.getIdTokenFromApi(queryParams['interaction_code']).pipe(
            tap(({ id_token }) => {
              localStorage.setItem('id_token', id_token);
              this.router.navigate(['/']);
            }),
            // @ts-ignore
            map(({ id_token }) => parseJwt(id_token)['sub']),
            switchMap((userId: string) => [
              PortalInfoLoad({ userId, portalPage: PortalPages.HOME }),
              LoginSuccess({userId}),
            ]),
            catchError(error => of(LoginFailure(error)))
          )
        )
      ),
    { dispatch: true }
  );

  checkLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckLogin),
        switchMap(() =>
          this.authService.verifyToken().pipe(
            withLatestFrom(this.portalPage$),
            switchMap(([, portalPage]) => {
              if (Boolean(localStorage.getItem('id_token'))) {
                // @ts-ignore
                const userId = parseJwt(localStorage.getItem('id_token'))[
                  'sub'
                ];
                return [PortalInfoLoad({ userId, portalPage }), LoginSuccess({userId})];
              } else {
                return of(Login());
              }
            }),
            catchError(() =>
              of(LoginFailure({ payload: { Error: 'Not signed in' } }))
            )
          )
        )
      ),
    { dispatch: true }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        switchMap(() =>
          this.authService.logOut().pipe(
            // @ts-ignore
            tap((res: { uri: string }) => {
              localStorage.removeItem('id_token');
              if (res?.uri) {
                window.location.assign(res.uri);
              }
            }),
            tap(() => LogoutComplete())
          )
        )
      ),
    { dispatch: false }
  );

  LoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginSuccess),
        tap(({userId}) => 
          this.rumService.addSessionAttributes({userId})
        )
      ),
    { dispatch: false }
  );

  LoginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginFailure),
        tap(() => {
          Boolean(localStorage.getItem('id_token'))
            ? localStorage.removeItem('id_token')
            : '';
        })
      ),
    { dispatch: false }
  );
}
