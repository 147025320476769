import { createSelector } from '@ngrx/store';
import { getPortalState } from '../reducers/portal.reducer';
import { DefaultUserGroups } from '../../models/default-user-groups.interface';
import { OpenRequestTypes } from '../../models/open-requests/open-request-types.model';

const loggedInToPortal = createSelector(
  getPortalState,
  ({ loggedIn, profile }) => loggedIn && Boolean(profile?.sub)
);

const loggingOutFromPortal = createSelector(
  getPortalState,
  ({ loggingOut }) => loggingOut
);

const loadedPortal = createSelector(getPortalState, ({ loaded }) => loaded);

const loadingPortal = createSelector(getPortalState, ({ loading }) => loading);

const getCurrentOrganization = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization
);

const getCurrentOrganizationId = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization?.organization_id
);

const getCurrentOrganizationName = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization?.name
);

const getCurrentOrganizationRole = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization?.roles ?? []
);

const getCurrentOrganizationAttestation = createSelector(
  getPortalState,
  ({ currentOrganization }) => Boolean(currentOrganization?.attestation)
);

const getCurrentOrganizationIdentification = createSelector(
  getPortalState,
  ({ currentOrganization }) => Boolean(currentOrganization?.identification)
);

const getCurrentOrganizationTypes = createSelector(
  getPortalState,
  ({ currentOrganization, organizations }) =>
    currentOrganization?.organization_types ??
    organizations.find(
      org => org.organization_id === currentOrganization.organization_id
    )?.organization_types ??
    []
);

const getUserProfile = createSelector(getPortalState, ({ profile }) => profile);

const getUserId = createSelector(getPortalState, ({ profile }) => profile?.sub);

const isUserStcEmployee = createSelector(getPortalState, ({ profile }) =>
  Boolean(profile?.stc)
);

const externalIdentificationInvitationSendToUser = createSelector(
  getPortalState,
  ({ profile }) => Boolean(profile.ext_identified)
);

const getBusinessInformation = createSelector(
  getPortalState,
  ({ businessInformation }) => businessInformation
);

const getBusinessRoles = createSelector(
  getPortalState,
  ({ businessInformation }) => businessInformation?.business_roles
);

/**
 *
 * @param role the code of the role
 * @returns boolean
 */
const userHasBusinessRole = (role: string) =>
  createSelector(getPortalState, ({ businessInformation }) =>
    businessInformation?.business_roles
      .map(businessRoles => businessRoles?.organization_role?.code)
      .some(roles => roles === role)
  );

const getOrganizations = createSelector(
  getPortalState,
  ({ organizations }) => organizations
);

const getChangeRequest = createSelector(
  getPortalState,
  ({ changeRequest }) => changeRequest
);

const getChangeRequestUserProfile = createSelector(
  getPortalState,
  ({ changeRequest }) =>
    changeRequest?.req_type === OpenRequestTypes.CHANGE_USER_PROFILE
);

const getServices = createSelector(getPortalState, ({ services }) => services);

const getMarketplaceServices = createSelector(
  getPortalState,
  ({ marketplace }) => marketplace
);

const getNotifications = createSelector(
  getPortalState,
  ({ notifications }) => notifications
);

const getPendingUsers = createSelector(
  getPortalState,
  ({ notifications }) => notifications.pending_users
);

const getChangeBusinessInfoRequest = createSelector(
  getPortalState,
  ({ notifications }) => notifications.change_business_info_request
);

const getQuarterlyReviewNotifications = createSelector(
  getPortalState,
  ({ notifications }) => notifications.quarterly_review
);

const getMissingFields = createSelector(
  getPortalState,
  ({ missingFields }) => missingFields
);

const showedMissingFielsdDialog = createSelector(
  getPortalState,
  ({ showedMissingFielsdDialog }) => showedMissingFielsdDialog
);

const getAttestationInformation = createSelector(
  getPortalState,
  ({ businessInformation: { last_validated, verified } }) =>
    last_validated
      ? {
          verified,
          last_validated,
        }
      : {
          verified: false,
          last_validated: ' ',
        }
);

const isAttestationEnabled = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization?.attestation
);

const isIdentificationEnabled = createSelector(
  getPortalState,
  ({ currentOrganization }) => currentOrganization?.identification
);

const isEmailVerified = createSelector(
  getPortalState,
  portal => portal.profile.email_verified
);

const getUserMaxRole = createSelector(
  getPortalState,
  ({ currentOrganization }) => {
    const containsRole = (role: DefaultUserGroups) =>
      currentOrganization?.roles.includes(role);

    switch (true) {
      case containsRole(DefaultUserGroups.ADMINS):
        return DefaultUserGroups.ADMINS;
      case containsRole(DefaultUserGroups.USERMANAGERS):
        return DefaultUserGroups.USERMANAGERS;
      case containsRole(DefaultUserGroups.SERVICEMANAGERS):
        return DefaultUserGroups.SERVICEMANAGERS;
      case containsRole(DefaultUserGroups.MEMBERS):
        return DefaultUserGroups.MEMBERS;
      case containsRole(DefaultUserGroups.PENDING):
        return DefaultUserGroups.PENDING;
      default:
        return DefaultUserGroups.PENDING;
    }
  }
);

const getMainOrganization = createSelector(
  getPortalState,
  ({ organizations }) => organizations.find(org => org.main_organization)
);

const adminOfMainOrganization = createSelector(
  getPortalState,
  ({ organizations }) => {
    const mainOrganization = organizations.find(org => org.main_organization);
    return (
      Boolean(mainOrganization) &&
      mainOrganization?.member_of === DefaultUserGroups.ADMINS
    );
  }
);

const getPortalInfo = createSelector(getPortalState, payload => payload);

const hasOrganizationWithAdminRights = createSelector(
  getPortalState,
  ({ organizations }) =>
    organizations.some(org => org.member_of === DefaultUserGroups.ADMINS)
);

const getFeatureToggle = (feature: string) =>
  createSelector(getPortalState, ({ features }) =>
    features[feature] ? features[feature] : false
  );

const getAllFeatureToggles = createSelector(
  getPortalState,
  ({ features }) => features
);

const currentQuarterlyReviewStatus = createSelector(
  getPortalState,
  (info) => info.profile.current_quarterly_review_status
);

const getProposedEmail = createSelector(
  getPortalState,
portal => portal.profile?.proposed_email);

const userCoupledToOrganization = createSelector(
  getPortalState,
portal => portal.organizations.length > 0);

const isPending = createSelector(
  getPortalState,
portal => portal.organizations[0]?.member_of === DefaultUserGroups.PENDING);


export const portalQuery = {
  loggedInToPortal,
  loggingOutFromPortal,
  loadedPortal,
  loadingPortal,
  getCurrentOrganization,
  getCurrentOrganizationName,
  getCurrentOrganizationId,
  getCurrentOrganizationRole,
  getCurrentOrganizationAttestation,
  getCurrentOrganizationIdentification,
  getCurrentOrganizationTypes,
  getQuarterlyReviewNotifications,
  getUserProfile,
  getUserId,
  isUserStcEmployee,
  externalIdentificationInvitationSendToUser,
  getBusinessInformation,
  getBusinessRoles,
  userHasBusinessRole,
  getOrganizations,
  getChangeRequest,
  getChangeRequestUserProfile,
  getServices,
  getMarketplaceServices,
  getNotifications,
  getPendingUsers,
  getChangeBusinessInfoRequest,
  getMissingFields,
  getAttestationInformation,
  getUserMaxRole,
  getMainOrganization,
  hasOrganizationWithAdminRights,
  getPortalInfo,
  isAttestationEnabled,
  isIdentificationEnabled,
  isEmailVerified,
  adminOfMainOrganization,
  showedMissingFielsdDialog,
  getFeatureToggle,
  getAllFeatureToggles,
  getProposedEmail,
  currentQuarterlyReviewStatus,
  userCoupledToOrganization,
  isPending
};
